@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

@tailwind base; 
@tailwind components; 
@tailwind utilities; 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0,1,1,0)
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 .6s infinite
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 .6s infinite
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 .6s infinite
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 .6s infinite
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0)
  }

  100% {
    transform: scale(1)
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1)
  }

  100% {
    transform: scale(0)
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0,0)
  }

  100% {
    transform: translate(24px,0)
  }
}

#loader {
  width: 100vw;
  height: 100vh;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center
}
